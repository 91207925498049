import { Html, Clone, useGLTF, useTexture } from '@react-three/drei';
import { forwardRef, useRef, useEffect} from 'react';

const DungeonSlice = forwardRef((props, ref) => {
    const { title, unreleased, url, credits, visible = true, img, song, onPlay, bandcamp} = props;
    const dungeonSlice = useGLTF('./dungeonSlice.glb');
    const { nodes, materials } = dungeonSlice;
    const PI = 3.141569;
    
    const songPath = 'https://overunderdog.web.app/songs/'

    const texture = img ? useTexture("./covers/" + img) : null;

    const handleError = (e) => {
        console.error('Error loading iframe:', e);
    };
    
    let details = navigator.userAgent; 
    let regexp = /iphone|ipad/i; 
    let isiOSDevice = regexp.test(details); 
    let padding = 0
    if(isiOSDevice){
        padding -= .17
    }

    // console.log(nodes, materials)
    const objectRef = useRef();

    useEffect(() => {
        if (objectRef.current) {
        objectRef.current.traverse((child) => {
            child.frustumCulled = false;
        });
        }
    }, [objectRef]);

    return (
        <group visible={visible} dispose={null} ref={ref} frustumCulled={false}>
            <Clone  object={dungeonSlice.scene} ref={objectRef}/>
            {visible && (
                <>
                    <Html
                        occlude
                        distanceFactor={1.2}
                        position={[-2.8, 1.85, 0.01]}
                        rotation={[ 20.55, 1.90, -1.70 ]}
                        transform
                        wrapperClass='itemText'
                        style={{ }}
                        center
                        frustumCulled={false}
                    >
                        <div id="itemTextEnclosure">
                            <h2>
                                {title.split('\n').map((line, index) => (
                                    <div key={index}>
                                        {line}
                                        <br />
                                    </div>
                                ))}
                            </h2>
                            <p>
                                {credits.split('\n').map((line, index) => (
                                    <span key={index}>
                                        {line}
                                        <br />
                                    </span>
                                ))}
                            </p>
                        </div>
                        
                        <div id="spacer" >
                            {song && (
                                <button id="playButton" onClick={() => onPlay(songPath + song)}>
                                    <img width="60px" height="60px" src="./button.gif" alt="Click to Play the song"/>
                                </button>
                            )}
                            <a href={bandcamp} id="bandcampButton" target="_blank" rel="noopener noreferrer">
                                <img width="60px" height="60px" src="./navigate.gif" alt="Click to go to bandcamp" />
                            </a>
                        </div>
                    </Html>

                    {url &&
                        <Html
                            occlude
                            distanceFactor={1.2}
                            position={[-4.16, 4.05 + padding, 0]}
                            rotation={[0, PI / 2, 0]}
                            transform
                            wrapperClass='vidIframe'
                            frustumCulled={false}
                        >
                            <iframe 
                                width="830" 
                                height="830" 
                                src={url+'??rel=1;'} 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerPolicy="no-referrer-when-downgrade" 
                                allowFullScreen
                                onError={handleError}
                                loading="lazy"
                            ></iframe>
                        </Html>}
                    {unreleased &&
                        <>
                            <Html
                                frustumCulled={false}
                                occlude
                                distanceFactor={1.2}
                                position={[-4.16, 4.05 + padding, 0]}
                                rotation={[0, PI / 2, 0]}
                                transform
                                wrapperClass='skeletonGif'
                            >
                                <img width="830" height="830" src={"./skeleton.gif"} ></img>

                            </Html>
                            <Html
                                occlude
                                distanceFactor={1.93}
                                position={[-3, 2.95, 0]}
                                rotation={[0, PI / 2, 0]}
                                transform
                                wrapperClass='unreleasedText'
                                center
                            >
                                <h2>( Unreleased )</h2>
                            </Html>
                        </>
                    }

                    {img && texture && (
                        <mesh position={[-4.16, 4.05, 0]} rotation={[0, PI / 2, 0]} frustumCulled={false}>
                            <planeGeometry args={[2.5, 2.5]} />
                            <meshBasicMaterial map={texture} />
                        </mesh>
                    )}
                </>
            )}
        </group>
    );
});

export default DungeonSlice;
