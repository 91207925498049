import { Html } from '@react-three/drei';
import { useRef, useEffect, useState, useCallback } from 'react';

export default function PasswordChecker({ setUnlocked, setHasCred }) {
    const inputField = useRef(null);
    const [clickCount, setClickCount] = useState(0);

    const handleTouch = useCallback((event) => {
        setClickCount(prevCount => prevCount + 1);
        console.log(clickCount);
        event.preventDefault();
        inputField.current?.focus();
    }, [clickCount]);

    const labelRef = useRef(null);

    useEffect(() => {
        if (labelRef.current) {
            document.addEventListener('click', handleTouch);
        }

        return () => {
            document.removeEventListener('click', handleTouch);
        };
    }, [handleTouch]);

    return (
        <>
            <Html
                occlude
                distanceFactor={0.6}
                position={[1.25, 3.67, 5.05]}
                rotation={[-0.74, -0.43, -0.37]}
                transform
                wrapperClass='passwordInput'
            >
                <div id="password">
                    <label htmlFor="passwordField" ref={labelRef}>
                        <span>Password:</span>
                        <input
                            id="passwordField"
                            name="password"
                            type="text"
                            ref={inputField}
                            onKeyDown={handlePassword}
                        />
                    </label>
                </div>
            </Html>
        </>
    );

    function handlePassword(event) {
        if (event.key === 'Enter') {
            let password = inputField.current.value;
            password = password.toLowerCase();
            document.removeEventListener('click', handleTouch);
            inputField.current?.blur();
            if (password === 'frog') {
                setHasCred(true);
                setUnlocked(true);
            } else {
                setUnlocked(true);
            }
        }
    }
}
