import React, { useEffect, useState } from 'react';
import CanvasWrapper from './CanvasWapper';


const App = () => {
    
    

    return (
            <CanvasWrapper />
    );
};

export default App;
