// AudioPlayer.js
import React, { useRef, useEffect } from 'react';
import { Html } from '@react-three/drei';

export default function AudioPlayer({ currentSong, volume, setIsPlaying }) {
    const audioRef = useRef();

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
        }
    }, [volume]);

    return (
        currentSong && (
           
                <audio
                    ref={audioRef}
                    src={currentSong}
                    autoPlay
                    preload={"true"}
                    controls={true}
                    controlsList="nodownload noplaybackrate"
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    onEnded={() => setIsPlaying(false)}
                />
        )
    );
}
