// Dungeon.js
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useThree } from '@react-three/fiber';
// import { Html } from '@react-three/drei';
import DungeonSlice from './dungeonSlice';
import credits from './credits.json';
import { useSpring, a } from '@react-spring/three';
import { useGesture } from '@use-gesture/react';

const PI = 3.141569;
const SLICES_VISIBLE = 5;

export default function Dungeon({ hasCred, setCurrentSong, setIsPlaying}) {
    const [spring, setSpring] = useSpring(() => ({ rotation: [0, 0, 0], config: { mass: 0.04, precision: 0.001 } }));
    const [visibleSlices, setVisibleSlices] = useState([]);
    const [rotation, setRotation] = useState([0, 0]);
    const dungeonSliceRefs = useRef([]);
    const groupRef = useRef();
    const spotLightRef = useRef();
    const lightTarget = useRef();
    const { size, viewport } = useThree();
    const aspect = size.width / viewport.width;

    const list = useMemo(() => {
        return credits.items
            .filter((item) => hasCred || !item.unreleased)
            .map((item, index) => ({ ...item, listIndex: index }));
    }, [credits.items, hasCred]);

    useEffect(() => {
        spotLightRef.current.target = lightTarget.current;
    }, [spotLightRef]);

    useEffect(() => {
        const initialSlices = getWrappedSlice(-2, 2);
        setVisibleSlices(initialSlices);
    }, []);

    const bind = useGesture({
        onDrag: ({ offset: [x, y], movement: [mx, my] }) => {
            let conv = (-x) * 0.004;
            setSpring({ rotation: [0, conv, 0] });
            setRotation([-conv, mx]);
        }
    }, { drag: { threshold: 30 } });

    useEffect(() => {
        if (rotation[0] === 0) return;
        adjustSlicePositions();
    }, [rotation]);

    useEffect(() => {
        let sliceIndex = Math.round(rotation[0] / (PI / 3));
        dungeonSliceRefs.current.forEach((ref, i) => {
            if (ref) {
                ref.rotation.y = ((PI / 3) * i) + (PI / 3 * sliceIndex);
            }
        });
    }, [visibleSlices]);

    const handlePlay = (song) => {
        setCurrentSong(song);
        setIsPlaying(true);
    };

    return (
        <>
            <mesh
                position={[0, -144, -22.517]}
                rotation={[PI / 2, 0, 0]}
                castShadow
                frustumCulled={false}
            >
                <planeGeometry args={[11, 11]} />
                <meshBasicMaterial color={"black"}  />
            </mesh>
            <a.group {...spring} {...bind()} ref={groupRef} position={[0.32, -151, -22.517]} dispose={null} frustumCulled={false}>
                {visibleSlices && visibleSlices.map((item, index) => (
                    <DungeonSlice
                        visible
                        key={`item-${item.index}-${item.listIndex}`}
                        ref={el => dungeonSliceRefs.current[index] = el}
                        title={item.title}
                        unreleased={item.unreleased}
                        url={item.url}
                        img={item.img}
                        credits={item.credits}
                        song={item.song}
                        onPlay={handlePlay}
                        bandcamp={item.bandcamp}
                        frustumCulled={false}
                    />
                ))}
            </a.group>
            <spotLight
                position={[0.44, -147.3, -22.517]}
                intensity={20}
                angle={.55}
                penumbra={.4}
                decay={1}
                color={'#f8b058'}
                ref={spotLightRef}
                frustumCulled={false}
            />
            <object3D ref={lightTarget} position={[.99, -147.45, -21.517]} frustumCulled={false}/>
            
        </>
    );

    function getFirstVisibleIndex(rotation) {
        let sliceIndex = Math.round(rotation[0] / (PI / 3)) % list.length;
        if (sliceIndex < 0) {
            sliceIndex = list.length + sliceIndex;
        }
        return sliceIndex;
    }

    function adjustSlicePositions() {
        let focus = getFirstVisibleIndex(rotation);
        let middle = visibleSlices[2]?.index;
        if (middle === focus) {
            return;
        }

        let newSlices = getWrappedSlice(focus - 2, focus + 2);
        setVisibleSlices(newSlices);
    }

    function getWrappedSlice(start, end) {
        const length = list.length;
        start = ((start % length) + length) % length;
        end = ((end % length) + length) % length;
        if (start <= end) {
            return list.slice(start, end + 1);
        } else {
            return list.slice(start).concat(list.slice(0, end + 1));
        }
    }
}
