import {  useFrame } from '@react-three/fiber'
import {  Environment, useGLTF, ContactShadows } from '@react-three/drei'
import { useState, useRef, useEffect } from 'react'

import studio from '@theatre/studio'
import extension from '@theatre/r3f/dist/extension'
import { getProject } from '@theatre/core'
import {  editable as ed, SheetProvider, PerspectiveCamera } from '@theatre/r3f'

//import projectState from './projectState.json'
import projectState from './Project.theatre-project-state.json'
import PasswordChecker from './PasswordChecker'
import Dungeon from './Dungeon'
import { Bloom, EffectComposer, ToneMapping } from '@react-three/postprocessing'

import { KernelSize } from 'postprocessing'
import { ToneMappingMode } from 'postprocessing'


const PI = 3.141569;
const sheet = getProject('Demo Project', { state: projectState }).sheet('Demo Sheet')
if (import.meta.env.DEV){
    studio.initialize()
    studio.extend(extension)
}

export default function Experience({setCurrentSong, setIsPlaying}) {

    const [renderCount, setRenderCount] = useState(0);
    useEffect(() => {
    if (renderCount < 2) {
        setRenderCount(renderCount + 1);
        return;
    }
    }, [renderCount]);
    /* Storing user's device details in a variable*/
    let details = navigator.userAgent; 
    
    /* Creating a regular expression  
    containing some mobile devices keywords  
    to search it in details string*/
    let regexp = /android|iphone|kindle|ipad/i; 
    
    /* Using test() method to search regexp in details 
    it returns boolean value*/
    let isMobileDevice = regexp.test(details); 

    let persp = 83
    if(isMobileDevice){
        persp = 83
    }


    let [unlocked, setUnlocked] = useState(false)
    let [hasCred, setHasCred] = useState(false)
    let [doneWalking, setDoneWalking] = useState(false)
    let [walkedUp, setWalkedUp] = useState(false)
    let [halfwayDown, setHalfwayDown] = useState(false)

    // camera.lookAt( 2,5,7)
    // camera.updateProjectionMatrix()
    
    const grove = useGLTF('./grove.glb')
    const { nodes, materials } = grove

    const mouth = useRef()
    const wobbleSpeed = sheet.object('Wobble Speed', { speed:  3.0  }) 
    const wobbleDistance = sheet.object('Wobble Distance', { distance: 0.0005  }) 
    // const wobbleDistance = 0.0005

    useFrame((state, delta) => {
        const time = state.clock.elapsedTime
        state.camera.position.y += Math.sin(time*wobbleSpeed.value.speed) * wobbleDistance.value.distance
        
    })

    //content unlocked
    useEffect(() => {
        if (!unlocked) return
        sheet.project.ready.then(() =>{
            sheet.sequence.play({ range: [4, 16]}).then(() => {setHalfwayDown(true)})
            
        })
    }, [unlocked])

    
    function walkup(e){
        if( !walkedUp ){
            setWalkedUp(true)
        }
        else{
            return
        }
        sheet.project.ready.then(() => sheet.sequence.play({ range: [0, 4]}).then(() => {setDoneWalking(true)} ))
    }

    return <SheetProvider sheet={sheet}>
        <EffectComposer>
            <ToneMapping mode={ ToneMappingMode.ACES_FILMIC } />
            <Bloom 
                kernelSize={KernelSize.VERY_SMALL}
                luminanceThreshold={.73}
                intensity={9} 
                levels={9} 
                mipmapBlur
            />
        </EffectComposer>
        
        <PerspectiveCamera 
            theatreKey="Camera" 
            makeDefault 
            position={[ -8, 4, 13 ]}
            fov={persp}
            near={0.1}
            far={200}
        />
        {/* <Perf position="bottom-left" /> */}

        
         
                
        <ambientLight intensity={.8} />
        <Environment 
            background
            files={[ './env/morningdewft.bmp','./env/morningdewbk.bmp', './env/morningdewup.bmp', './env/morningdewdn.bmp', './env/morningdewrt.bmp','./env/morningdewlf.bmp',]}
            backgroundBlurriness={0}
            backgroundIntensity={1}
            backgroundRotation={[0, .88, 0]} // optional rotation (default: 0, only works with three 0.163 and up)
            environmentRotation={[0, .88, 0]}
            environmentIntensity={1.8} 
        />
        {/* <Sky azimuth={0} /> */}

        

        <group >
            <mesh
                
                geometry={nodes.Ground.geometry}
                
                material={materials['Material']}
                frustumCulled={halfwayDown}
            />
            <mesh
                onClick={() => {
                    setCurrentSong("https://overunderdog.web.app/songs/overunderdog.world AMBIENT SLOWED.wav");
                    setIsPlaying(true);
                    walkup()
                }}
                
                geometry={nodes.Tree.geometry}
                material={materials['TreeMat']}
                frustumCulled={halfwayDown}
            />
            <mesh
                geometry={nodes.TreeEyes.geometry}
                material={materials['EyesMat']}
                frustumCulled={halfwayDown}
            />
            <ed.mesh
                theatreKey="Mouth"
                ref={mouth}
                geometry={nodes.TreeMouth.geometry}
                material={materials['MouthMat']}
                frustumCulled={halfwayDown}
            />
            <mesh
                geometry={nodes.Tube.geometry}
                material={materials['TubeMat']}
                frustumCulled={halfwayDown}
            />
            
            <mesh
               
                geometry={nodes.Podium.geometry}
                material={materials["Podium.003"]}
                frustumCulled={halfwayDown}
            >
            {doneWalking && renderCount % 2 === 0 ? (
                 <PasswordChecker setUnlocked={setUnlocked} setHasCred={setHasCred} />
            ) : null}
            </mesh>
        </group>
        
        <Dungeon hasCred={hasCred} setCurrentSong={setCurrentSong} setIsPlaying={setIsPlaying}/>
        
        

        

    </SheetProvider>


}