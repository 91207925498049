import React, { useEffect, useState, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import Experience from './Experience';

import { Perf } from 'r3f-perf'
import AudioPlayer from './AudioPlayer';

export default function CanvasWrapper() {
  const [height, setHeight] = useState("100dvh");
  const [width, setWidth] = useState("100dvw");
  const canvasRef = useRef();
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);


  let details = navigator.userAgent; 
  let regexp = /android|iphone|kindle|ipad/i; 
  let isMobileDevice = regexp.test(details); 

  useEffect(() => {
    
    const measureCanvasHeight = () => {
      const canvasElement = canvasRef.current;
      let canvasHeight = canvasElement.clientHeight;
      
      let canvasWidth = canvasElement.clientWidth;

      if (canvasHeight % 2 !== 0) {
        setHeight(`${canvasHeight + 1}px`);
      }
      if (canvasWidth % 2 !== 0) {
        setWidth(`${canvasWidth + 1}px`);
      }
    };
    if(isMobileDevice){
      window.requestAnimationFrame(measureCanvasHeight);
      const handleResize = () => {
        setHeight("100dvh");
        setWidth("100dvw");
        measureCanvasHeight();
      };

      
      window.addEventListener("resize", handleResize);
      return () => {
      window.removeEventListener("resize", handleResize);
      };
    }
    
    // handlePlay('https://overunderdog.web.app/songs/Feel like a Man - Bonemachine.wav')
  }, []);

  const handlePlay = (song) => {
    setCurrentSong(song);
    setIsPlaying(true);
};

  return (<>
    <div style={{ height: height, width: width }} ref={canvasRef}>
      <Canvas
        shadows
        gl={{  antialias: false}}
        className='r3f'
        dpr={1}
      >
        {/* <Perf position="top-left" 
          deepAnalyze
        /> */}
        <Experience setCurrentSong={setCurrentSong} setIsPlaying={setIsPlaying}/>
      </Canvas>
      <AudioPlayer
        currentSong={currentSong}
        volume={volume}
        setIsPlaying={setIsPlaying}
      />
      <span id='tagline'>site by <a href="https://www.danielimondo.com/" target="_blank">Daniel Imondo</a></span>
      <span id='email'><a href="mailto:cole.roberts.m@gmail.com">cole.roberts.m@gmail.com</a></span>
      <span id='insta'><a href="https://www.instagram.com/overunderdog/" target="_blank">instagram</a></span>
      
    </div>
    </>
  );
}
